
import Vue from "vue";
import OccurrenceStatusLight from "@/components/OccurrenceStatusLight.vue";

export default Vue.extend({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    "occurrence-status-light": OccurrenceStatusLight
  }
});


import Vue from "vue";
export default Vue.extend({
  props: {
    selectedDocument: {
      type: Object,
      required: true
    },
    documents: {
      type: Array as () => any[],
      required: true
    }
  }
});

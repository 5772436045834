
import Vue, { Component } from "vue";
import { Occurrence, Document } from "@/types";
import BaseDialog from "@/components/BaseDialog.vue";
import OccurrenceDetailModalOccurrence from "@/components/OccurrenceDetailModalOccurrence.vue";
import OccurrenceDetailModalPerson from "@/components/OccurrenceDetailModalPerson.vue";
import OccurrenceDetailModalImages from "@/components/OccurrenceDetailModalImages.vue";
import { occurrence } from "../store/modules/occurrence.store";
const defaultDocument: Document = {
  creationDate: new Date(),
  id: "",
  modificationDate: new Date(),
  path: require("@/assets/kein-foto-hochgeladen.jpg"),
  type: {}
};
const pdfService = () => import("@/services/pdfService");

export default Vue.extend({
  props: ["item", "value"],
  methods: {
    createPdf() {
      pdfService().then(module => {
        const generate = module.default;
        generate.getPdf(this.item, this.documents, this.item.number);
      });
    },
    menuX() {
      const menuBtn = this.$refs.menuBtn as Vue;
      return menuBtn.$el.getBoundingClientRect().left + 40;
    },
    menuY() {
      const menuBtn = this.$refs.menuBtn as Vue;
      return menuBtn.$el.getBoundingClientRect().top + 40;
    },
    openMenu() {
      this.x = this.menuX();
      this.y = this.menuY();
      this.showMenu = !this.showMenu;
    },
    openMenuAndClearTimeout() {
      clearTimeout(this.timeout);
      this.openMenu();
    }
  },
  components: {
    occurrence: OccurrenceDetailModalOccurrence,
    person: OccurrenceDetailModalPerson,
    gallery: OccurrenceDetailModalImages,
    "base-dialog": BaseDialog
  },
  computed: {
    dialog: {
      get(this: any): boolean {
        return this.value;
      },
      set(this: any, value: boolean): void {
        this.$emit("input", value);
      }
    },
    documents(): Document[] {
      if (this.item.id) {
        return this.$store.getters["document/documents"](this.item.id);
      } else {
        return [];
      }
    },
    selectedDocument: {
      get(): Document {
        if (this.documents.length > 0 && this.selectedDocumentData.id === "") {
          return this.documents[0];
        }
        return this.selectedDocumentData;
      },
      set(newDocument: Document): void {
        this.selectedDocumentData = newDocument;
      }
    }
  },
  watch: {
    value() {
      if (!this.value) {
        clearTimeout(this.timeout);
        this.showMenu = false;
      } else {
        this.$nextTick(function(this: any) {
          this.timeout = setTimeout(this.openMenu, 750);
          this.timeout = setTimeout(this.openMenu, 3600);
        });
      }
    },
    item() {
      if (this.item.id) {
        this.selectedDocumentData = { ...defaultDocument };
        this.$store.dispatch("document/getAllDocuments", this.item.id);
      }
    }
  },
  data() {
    return {
      selectedDocumentData: { ...defaultDocument },
      showMenu: false,
      x: 0,
      y: 0,
      timeout: 0
    };
  }
});


import Vue from "vue";
import LayoutTopMenu from "@/layouts/LayoutTopMenu.vue";
import { Occurrence, RealEstate, User } from "../types";
import BaseTable from "@/components/BaseTable.vue";
import RangeDatePicker from "@/components/RangeDatePicker.vue";
import GroupedSelect from "@/components/GroupedSelect.vue";
import OccurrenceExportCsv from "@/components/OccurrenceExportCsv.vue";
import OccurrenceDetailModal from "@/components/OccurrenceDetailModal.vue";
import OccurrenceStatusLight from "@/components/OccurrenceStatusLight.vue";
import moment from "moment";
import debounce from "lodash/debounce";
import i18n from "../plugins/i18n";
import OccurrenceCreateModal from "@/components/OccurrenceCreateModal.vue";
import BaseDataView from "@/components/BaseDataView.vue";
import OccurrenceService from "@/services/occurrenceService.vue";
import { Occurrenceservice } from "../services";
const Component = Vue.extend({
  created(): void {
    this.$emit("update:layout", LayoutTopMenu);
    this.$store.dispatch(
      "occurrence/getDateFilteredOccurrences",
      this.dateRange
    );
    this.$store.dispatch("realEstate/getAllRealEstates");
  },
  watch: {},
  computed: {
    me(): User {
      return this.$store.getters["authentication/user"];
    },
    occurrences(): Occurrence[] {
      const items = this.$store.getters["occurrence/occurrencesFiltered"](this.filters);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.pagination.serverItemsLength = items.length;
      return items;
    },
    occurrencesLoading(): boolean {
      return this.$store.getters["occurrence/isLoading"];
    },
    realEstates(): RealEstate[] {
      const data = this.$store.getters["realEstate/realEstates"];
      return data;
    },
    realEstatesLoading(): boolean {
      return this.$store.getters["realEstate/isLoading"];
    }
  },
  methods: {
    generateXlsx() {
      const params: {
        date_from?: string;
        date_to?: string;
        status?: string;
        realEstateId?: string;
        number?: string;
        realEstate?: string;
        buildingName?: string;
        occurrenceType?: string;
        date?: string;
        tradeType?: string;
        errorCause?: string;
        fetch_limit?: number;
      } = {};
      const filters: any = this.filters;
      if (filters.number) {
        params.number = filters.number;
      }
      if (filters.status) {
        params.status = filters.status;
      }
      if (filters.realEstateId) {
        params.realEstateId = filters.realEstateId;
      }
      if (filters.buildingName) {
        params.buildingName = filters.buildingName;
      }
      if (filters.date) {
        params.date = filters.date;
      }
      if (filters["occurrenceType.name"]) {
        params.occurrenceType = filters["occurrenceType.name"];
      }
      if (filters["tradeType.name"]) {
        params.tradeType = filters["tradeType.name"];
      }
      if (filters["errorCauseType.name"]) {
        params.errorCause = filters["errorCauseType.name"];
      }
      params.date_from = moment(this.dateRange.from).format("YYYY-MM-DD");
      params.date_to = moment(this.dateRange.to).format("YYYY-MM-DD");

      Occurrenceservice.getOccurrenceExcel(i18n.locale, params);
    },
    setFilters: debounce(function(this: any, filters: any): void {
      this.filters = filters;
    }, 500),
    setDateRange(range: { from: Date; to: Date }) {
      this.dateRange = range;
      this.$store.dispatch(
        "occurrence/getDateFilteredOccurrences",
        this.dateRange
      );
    },
    setRealEstateFilter(realEstates: string[]) {
      this.$set(this.filters, "realEstateId", realEstates);
    },
    openDetails(id: string, e: Event) {
      e.stopPropagation();
      this.dialogItem =
        this.occurrences.find(item => {
          return item.id === id;
        }) || {};
      this.detailDialog = true;
    }
  },
  data() {
    const dateFrom = moment();
    dateFrom.set({ hour: 0, minute: 0, second: 0 });
    dateFrom.subtract(1, "year");
    const dateTo = moment();
    dateTo.set({ hour: 23, minute: 59, second: 59 });
    return {
      dialogItem: {},
      detailDialog: false,
      createDialog: false,
      sortBy: "number",
      sortDesc: true,
      pagination: {
        itemsPerPage: 50,
        serverItemsLength: 0
      },
      filters: {},
      dateRange: { from: dateFrom.toDate(), to: dateTo.toDate() },
      headers: [
        {
          text: "OCCURRENCE.NUMBER",
          value: "number",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.REAL_ESTATE",
          value: "realEstate",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.BUILDING",
          value: "buildingName",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "FILTER.RECORDED",
          value: "date",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.OCCURRENCE_TYPE",
          value: "occurrenceType.name",
          align: "left",
          filterValues: [
            "Allg. Meldung",
            "Bereitschaftseinsatz",
            "Einbruch/Diebstahl",
            "Lagerung",
            "Mangel-Gewährleistungsverfolgung",
            "Mangel, Störung, Beschädigung",
            "Vandalismus"
          ],
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.TRADE",
          value: "tradeType.name",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.ERROR_TYPE",
          value: "errorCauseType.name",
          align: "left",
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.STATUS",
          value: "occurrenceStatus.name",
          align: "left",
          filterValues: ["Offen", "Erledigt", "In Bearbeitung"],
          filterable: true,
          sortable: true
        },
        {
          text: "OCCURRENCE.REPORT",
          value: "report",
          align: "left",
          sortable: false,
          filterable: false
        }
      ],
      subHeaders: [
        {
          text: "OCCURRENCE.INSTALLATION",
          value: "installation",
          align: "left",
          sortable: false
        },
        {
          text: "OCCURRENCE.LEVEL",
          value: "ebene",
          align: "left",
          sortable: false
        },
        {
          text: "OCCURRENCE.LOCATION",
          value: "location",
          align: "left",
          sortable: false
        },
        {
          text: "OCCURRENCE.DETAIL",
          value: "description",
          align: "left",
          sortable: false
        }
      ]
    };
  },
  components: {
    "base-table": BaseTable,
    "range-date-picker": RangeDatePicker,
    "grouped-select": GroupedSelect,
    "occurrence-export-csv": OccurrenceExportCsv,
    "occurrence-detail-modal": OccurrenceDetailModal,
    "occurrence-create-modal": OccurrenceCreateModal,
    "occurrence-status-light": OccurrenceStatusLight,
    "base-data-view": BaseDataView
  }
});
export default Component;

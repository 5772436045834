import { render, staticRenderFns } from "./OccurrenceDetailModalImages.vue?vue&type=template&id=79fbad87&scoped=true&"
import script from "./OccurrenceDetailModalImages.vue?vue&type=script&lang=ts&"
export * from "./OccurrenceDetailModalImages.vue?vue&type=script&lang=ts&"
import style0 from "./OccurrenceDetailModalImages.vue?vue&type=style&index=0&id=79fbad87&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79fbad87",
  null
  
)

export default component.exports

import Vue from "vue";
import { saveAs } from "file-saver";
import { resolve } from "@/mixins";
import moment, { isDate } from "moment";
import logger from "@/icslogger";
export default Vue.extend({
  data() {
    const fields = [];
    const headers = [];
    for (const key of Object.keys(this.reportfields)) {
      if (!key || !this.reportfields[key]) {
        throw new Error("error json report fields");
      }
      fields.push(key);
      headers.push(this.reportfields[key]);
    }
    return {
      fields,
      headers
    };
  },
  props: {
    jsonData: {
      type: Array as () => any[],
      required: true
    },
    reportfields: {
      type: Object,
      required: true
    },
    filename: {
      type: String,
      default: "data.csv"
    },
    separator: {
      type: String,
      required: false,
      default: ";"
    },
    sorting: {
      type: Object
    }
  },
  methods: {
    async generate(): Promise<void> {
      logger.info("Generate CSV", "User exports occurrences as CSV file");
      const sortingOrder = this.sorting.descending ? -1 : 1;
      const sorted = this.jsonData.slice().sort((a, b) => {
        const aValue = resolve(this.sorting.sortBy, a);
        const bValue = resolve(this.sorting.sortBy, b);
        if (typeof aValue === "string" && typeof bValue === "string") {
          if (aValue == null || aValue.trim() == "") {
              return -1 * sortingOrder;
            } 
            if (bValue == null || bValue.trim() == "") {
              return 1 * sortingOrder;
            }
          return (
            aValue.toLowerCase().localeCompare(bValue.toLowerCase()) *
            sortingOrder
          );
        } else if (isDate(aValue) && isDate(bValue)) {
          return (
            (new Date(aValue).getTime() - new Date(bValue).getTime()) *
            sortingOrder
          );
        } else {
          return 0;
        }
      });
      const body = this.bodyData(sorted);
      const strData = this.convertToExcel(body);
      return this.export(strData);
    },
    async export(data: string): Promise<void> {
      // Add BOM otherwise Excel does not recognize UTF8 encoding
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, data], {
        type: "text/plain;charset=utf-8"
      });
      saveAs(blob, this.filename + ".csv");
    },
    bodyData(data: any[]): string {
      let body = "";
      for (const dataItem of data) {
        const rowItems = [];
        
        if (dataItem.responsiblePerson) {
          dataItem.responsiblePersonName = dataItem.responsiblePerson.firstName + " " + dataItem.responsiblePerson.lastName;
        }

        for (const field of this.fields) {
          const resolvedValue = resolve<any>(field, dataItem);
          let fieldValue = resolvedValue !== null ? resolvedValue : " ";

          if (field === "date") {
            fieldValue = moment(fieldValue).format("DD.MM.YYYY");
          } else if (field === "has_warranty") {
            fieldValue = fieldValue === true ? "Ja" : "Nein";
          } else if (typeof fieldValue === "undefined") {
            fieldValue = " ";
          } else {
            fieldValue = fieldValue.toString().replace(/(\r\n|\n|\r|;)/gm, " ");
          }
          rowItems.push(fieldValue);
        }
        if (rowItems) {
          body += rowItems.join(";") + "\n";
        }
      }
      return body;
    },
    convertToExcel(body: string): string {
      return this.headers.join(this.separator) + "\n" + body;
    }
  }
});

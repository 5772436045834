
import Vue, { Component } from "vue";
import { Occurrence, Document, User } from "@/types";
import BaseDialog from "@/components/BaseDialog.vue";
export default Vue.extend({
  props: ["value"],
  methods: {
    submitOccurrence(e: Event) {
      if (!(this.$refs.form as any).validate()) {
        return;
      }
      this.$store.dispatch("occurrence/addOccurrence", this.formData);
    }
  },
  components: {
    "base-dialog": BaseDialog
  },
  computed: {
    dialog: {
      get(this: any): boolean {
        return this.value;
      },
      set(this: any, value: boolean): void {
        this.$emit("input", value);
      }
    }
  },
  watch: {},
  data() {
    return {
      formData: {}
    };
  }
});


import Vue from "vue";
import ExportCsv from "@/components/ExportCsv.vue";
import i18n from "../plugins/i18n";
import { Occurrence } from "../types";
import moment from "moment";

export default Vue.extend({
  components: {
    "export-csv": ExportCsv
  },
  computed: {
    filename() {
      const occurrenceTranslation =
        (i18n.t("OCCURRENCE.TITLE") as string) || "Occurrences";
      return occurrenceTranslation + "_" + moment().format("YYYY_MM_DD");
    }
  },
  data() {
    // console.log(this.sorting);
    return {
      json_fields: {
        number: i18n.t("OCCURRENCE.NUMBER"),
        realEstate: i18n.t("OCCURRENCE.REAL_ESTATE"),
        buildingName: i18n.t("OCCURRENCE.BUILDING"),
        date: i18n.t("FILTER.RECORDED"),
        responsiblePersonName: i18n.t("OCCURRENCE.RESPONSIBLE_PERSON"),
        "occurrenceType.name": i18n.t("OCCURRENCE.OCCURRENCE_TYPE"),
        "tradeType.name": i18n.t("OCCURRENCE.TRADE"),
        "errorCauseType.name": i18n.t("OCCURRENCE.ERROR_TYPE"),
        "installation.name": i18n.t("OCCURRENCE.INSTALLATION"),
        "installation.ebene": i18n.t("OCCURRENCE.LEVEL"),
        "installation.location": i18n.t("OCCURRENCE.LOCATION"),
        "occurrenceStatus.name": i18n.t("OCCURRENCE.STATUS"),
        description: i18n.t("OCCURRENCE.DETAIL"),
        errorCauseDetails: i18n.t("OCCURRENCE.NOTE")
      }
    };
  },
  props: {
    items: {
      type: Array as () => any[],
      required: true
    },
    sorting: {
      type: Object
    }
  }
});

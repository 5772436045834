import { render, staticRenderFns } from "./OccurrenceStatusLight.vue?vue&type=template&id=022c622c&scoped=true&"
import script from "./OccurrenceStatusLight.vue?vue&type=script&lang=ts&"
export * from "./OccurrenceStatusLight.vue?vue&type=script&lang=ts&"
import style0 from "./OccurrenceStatusLight.vue?vue&type=style&index=0&id=022c622c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022c622c",
  null
  
)

export default component.exports